h1{
    color: #fff1b9;
    position: relative;
    padding: 10px;
    padding-left: 0px;
    font-size: 30px;
    font-family: 'Raleway';
}

.select-month{
    position: relative;
    width: 300px;
    height: 50px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 2px solid #cdbb71;
    border-radius: 12px;
    padding-left: 15px;
    opacity: 1;   
    left: 50px;
    font-family: 'Raleway';
    margin-bottom: 25px;
}

.table-daily{
    padding: 5px;
    padding-left: 70px;
    position: relative;
    right: 25px;
}

.table-monthly{
    padding: 5px;
    padding-left: 70px;
    position: relative;
    right: 25px;
}

.header-monthly{
    padding-left: 50px;
}

@media (max-width: 768px) {
    table tbody {
      font-size: 16px;
    }
  
    table th {
      font-size: 20px;
    }
  }

  

#contact-links {
  position: fixed;
  left: 0;
  top: 50%;
  background: #fcfcfc;
  border: 1px solid #ff8500;
  border-radius: 12px;
  padding: 10px;
  width: 250px;
  transition: transform 0.3s ease-in-out;
  z-index: 9998;
  transform: translateX(-100%);
  font-family: 'Raleway';
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

#contact-links:hover {
    transform: translateX(0);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* New */
    transition: box-shadow 0.3s ease;
}

#trigger {
    position: absolute;
    right: -25px;
    top: 50%;
    background: #cdbb71;
    border: 2px solid #ff8500;
    border-radius: 0px 12px 12px 0px;
    padding: 10px;
    cursor: pointer;
    z-index: 9999;
    transform: translateY(-50%);
    background-color: #ff8400;
    font-weight: bolder;
    color: #cdbb71;
}

#contact-links a {
    display: block;
    margin-bottom: 5px;
    color: black;
    text-decoration: none;
    border-radius: 5px; /* New */
    background-color: #fff; /* New */
    padding: 10px; /* New */
    margin: 5px 0; /* New */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); /* New */
    display: flex; /* New */
    align-items: flex-start;
    justify-content: flex-start;
}

#contact-links a:hover {
    color: #cdbb71;
}

.image-next{
    position: relative;
    left: 8px;
    font-weight: bolder;
}

.img-contact{
    max-width: 20px;
    max-height: 20px;
    fill: #333;
}

.text-contact{
    margin-left: 30px;
    font-size: 25px;
}

.wa-img{
    position: relative;
    max-width: 30px;
    max-height: 30px;
}

.fb-img{
    position: relative;
    max-width: 25px;
    max-height: 25px;
    left: 3px;
}

.tele-img{
    position: relative;
    max-width: 30px;
    max-height: 30px;
}

.ig-img{
    position: relative;
    max-width: 25px;
    max-height: 25px;
    left: 3px;
}

.text-censored{
  color: red !important;
}

.wa-img, .tele-img, .ig-img, .fb-img {
  margin-right: 15px;
}

.wa-text, .tele-text, .phone-text, .web-text {
  flex-grow: 1;
  text-align: center;
  align-self: flex-start;
  font-family: 'Raleway';
  font-size: 20px;
}