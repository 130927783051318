.header-logo{
  cursor: pointer;
  margin-top: 20px;
  padding: 20px;
  max-width: 200px;
  flex: 1;  /* New */
}

.header-menu {
display: flex;
justify-content: space-between;
}

@media (max-width: 768px) {
.header-menu {
  display: block;
  justify-content: space-between;
}
}

.menu-button{
cursor: pointer;
color: white;
font-size: 20px;
text-transform: uppercase;
margin-top: 20px;
}

.menu-button:focus {
  outline: none;
}

.menu-items {
display: flex;
justify-content: flex-end;
align-items: center;
list-style-type: none;
}
.menu-items li {
margin-right: 1rem;
}

.menu-items li:last-child {
margin-right: 250px;
}

.menu-items a {
text-decoration: none;
color: #ffffff
}

.menu-items a:hover {
color: #8b8b8b;
font-weight: 600;
text-decoration: none !important;
}

@media only screen and (max-width: 600px) {
.menu-button{
    font-size: 16px;
    top: -50px;
    left: 90%;
}

.header-logo{
    padding: 10px;
}

.menu-items{
    align-items: center;
}
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
.menu-button{
    font-size: 18px;
    top: -60px;
    left: 90%;
}

.header-logo{
    padding: 15px;
}

.menu-items{
    flex-direction: row;
    justify-content: space-evenly;
}
}

@media screen and (max-width: 1200px) {
.menu-button {
  left: 1000px;
}
}

@media screen and (max-width: 992px) {
.menu-button {
  left: 800px;
}
}

@media screen and (max-width: 768px) {
.menu-button {
  left: 600px;
}
}