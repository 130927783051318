.footer {
    color: #808080;
    padding: 50px 0;
    display: flex;            /* New: Use Flexbox */
    justify-content: center;  /* New: Center the text horizontally */
    align-items: center;      /* New: Center the text vertically */
    font-weight: 600;
    font-size: 13px;
    bottom: 0;
}

.copyright{
    font-family: "Montserrat", Tahoma, Arial, Helvetica, sans-serif, "Microsoft Yahei";
    margin-right: -40px;
}