body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  border: 2px solid #ff8500;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

table th{
  padding: 7px !important;
  opacity: 1;
  text-align: center;
  padding-bottom: 10px;
  font-size: 24px;
  color:#fff1b9 !important;
  background-color: #ff8400;
  font-family: 'Raleway', sans-serif;
}

table thead tr {
  border-spacing: 0 30px;
}

table tbody{
  color: #fff1b9;
  font-size: 20px;
  text-align: center;
  font-family: 'Raleway', sans-serif;
}

@media (max-width: 768px) {
  table tbody {
    font-size: 16px;
    display: inline-block;
    overflow: auto;
    width: 334px;
    scrollbar-color: red yellow;
  }

  table th {
    font-size: 20px;
  }
}